<template>
<div class="portfolio">
    <header>
      <h1 id="headheader">{{ name }}</h1>
      <nav>
        <ul>
          <li><a href="#about">O mnie</a></li>
          <li><a href="#projects">Projekty</a></li>
          <li><a href="#contact">Kontakt</a></li>
        </ul>
      </nav>
    </header>
    <main>
      <section id="about">
        <h2>O mnie</h2>
        <p>{{ bio }}</p>
      </section>
      <section id="projects">
        <h2>Projekty</h2>
        <ul>
          <li v-for="project in projects" :key="project.id">
            <h3>{{ project.title }}</h3>
            <p>{{ project.description }}</p>
            <a :href="project.url">{{ project.url }}</a>
          </li>
        </ul>
      </section>
      <section id="contact">
        <h2>Kontakt</h2>
        <form>
          <label for="name">Imię:</label>
          <input type="text" id="name" name="name">
          <label for="email">Email:</label>
          <input type="email" id="email" name="email">
          <label for="message">Wiadomość:</label>
          <textarea id="message" name="message"></textarea>
          <button type="submit">Wyślij</button>
        </form>
      </section>
    </main>
    <footer>
      <p>{{ copyright }}</p>
    </footer>
  </div>
</template>
 <script>
export default {
  name: 'PortfolioApp',
  data() {
    return {
      name: 'Wojciech Gruszczyński',
      bio: 'Jestem programistą z 3-letnim doświadczeniem, specjalizującym się w języku JavaScript i frameworku Vue.js. Zawsze staram się tworzyć prosty, przejrzysty i łatwy do utrzymania kod.',
      projects: [
        {
          id: 1,
          title: 'Sklep internetowy Homemeble.pl',
          descriptionPath: 'projekt1.txt',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi lacinia in ante vitae hendrerit.',
          url: 'https://przykład.com/projekt1'
        },
        {
          id: 2,
          title: 'Projekt 2',
          descriptionPath: 'projekt1.txt',
          description: 'Praesent ac ante nunc. Nunc id ullamcorper justo. Suspendisse euismod, velit vel pulvinar volutpat, dolor libero.',
          url: 'https://przykład.com/projekt2'
        },
        {
          id: 3,
          title: 'Projekt 3',
          descriptionPath: 'projekt1.txt',
          description: 'Donec aliquet dolor sed libero tincidunt bibendum. Curabitur fermentum feugiat est, vel pretium justo condimentum ac.',
          url: 'https://przykład.com/projekt3'
        }
      ],
      copyright: 'Wojciech Gruszczyński © ' + new Date().getFullYear()
    };
  },
  mounted() {
    this.loadDescriptions()
  },
  methods: {
    loadDescriptions() {
      for (let i = 0; i < this.projects.length; i++) {
        const project = this.projects[i];
        fetch('descriptions/'+project.descriptionPath)
          .then(res => res.text())
          .then(text => {
            this.projects[i] = Object.assign({}, project, { description: text });
          })
      }
    }
  }
};
</script>
 <style>
/* Reset styles */
*, *::before, *::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 /* Basic styles */
body {
  margin-top: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  background-color: #f7f7f7;
}
 h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  margin: 1rem 0;
}
 #headheader {
   margin: 0;
}
 h1 {
  font-size: 3rem;
  color: #222;
  text-shadow: 1px 1px 3px #fff;

}
 h2 {
  font-size: 2.5rem;
  color: #333;
}
 h3 {
  font-size: 2rem;
  color: #555;
}
 header {
  background: #f7f7f7;
  font-family: 'Lato', sans-serif;
    color: #333;
    padding: 2rem;
    text-align: center;
    background-image: url('/public/img/unsp.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    animation: fadeInUp 2s ease-in-out;
  }
  
  @keyframes fadeInUp {
    from { transform: translateY(50px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
  }
 nav {
  display: flex;
  justify-content: flex-end
}
nav ul {
  list-style: none;
  display: flex;
  background-color: rgba(255, 255, 255, 0.8);
  opacity: 0.9;
  padding: 1rem 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}
 nav ul li + li {
  margin-left: 2rem;
  
}
 nav a {
  font-family: 'Lato', sans-serif;
    color: #333;
  text-decoration: none;
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 0.2s ease-in-out;
}
 main {
  max-width: 1024px;
  margin: 0 auto;
  padding: 2rem;
}
 section {
  margin: 3rem 0;
}
 ul {
  list-style: none;
}
 a {
  color: #007aff;
  text-decoration: none;
  border-bottom: 1px solid #007aff;
}

 form {
  display: flex;
  flex-direction: column;
  max-width: 480px;
  margin: 0 auto;
}
 label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}
 input,
textarea {
  padding: 0.5rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}
 button[type='submit'] {
  padding: 0.5rem 1rem;
  margin-top: 1rem;
  border: none;
  border-radius: 4px;
  background: #007aff;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  cursor: pointer;
}
 button[type='submit']:hover {
  background: #222;
}
 footer {
  background: #222;
  color: #fff;
  text-align: center;
  padding: 1rem;
}

</style>